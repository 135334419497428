html,
body,
#root,
#root > div {
  height: 100%;
}

.box-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.main-bg {
  background-color: #198754;
}

.main-color {
  color: #198754;
}

.main-light-bg {
  background-color: #557d53;
}

.main-light-color {
  color: #557d53;
}

.TableContainer-root {
  border-radius: 8px;
}

.table-color {
  color: #ffffff;
}

.table-color :focus {
  color: #898484;
}

.table-color :hover {
  color: #393737;
}

.table-color :active {
  color: #ffffff;
}

.MuiTableCell-head.TableFixedCell-fixedCell {
  background: #198754 !important;
}

/* *{
  -ms-overflow-style: none;
}
::-webkit-scrollbar {
  display: none;
} */

*::-webkit-scrollbar {
  width: 0.5em;
}

*::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.33);
  border-radius: 20px;
}

@media only screen and (max-width: 600px) {
  /* Adjust styles for screens with a width of 600px or less (typical for mobile) */
  ::-webkit-scrollbar {
    width: 0.2em;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
  }
}

/* .gbutton, */
.gparent,
.parent {
  display: none;
}

.parent:has(.child) {
  display: block;
}

.gparent:has(.parent):has(.child) {
  display: block;
}

form {
  width: 100%;
}